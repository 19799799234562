.projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.project {
  display: flex;
  flex-direction: column;
  width: 80%;
  background-color: #f5f5f7;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  overflow: hidden;
}

.project-image {
  width: 100%;  /* Ensures image covers the full width */
  height: auto;
  object-fit: cover;
}

.project-info {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.read-more, .live-link {
  text-decoration: none;
  color: #007aff;
  font-weight: bold;
}

.read-more:hover, .live-link:hover {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .project {
      flex-direction: row;
      height: 550px;
  }

  .project-info {
      width: 50%;
  }
}

@media (max-width: 768px) {
  .project {
      flex-direction: column;
      padding: 10px;  /* Adds padding around the content */
  }

  .project-info {
      width: 100%;  /* Ensures the content uses full width */
      padding: 10px;  /* Adds padding inside the content area to prevent clipping */
  }

  .project-image {
      width: 100%;  /* Ensures the image is responsive and fills the width */
  }

  .button-container {
      flex-direction: column;
      align-items: center;
      padding: 10px 0;  /* Adds vertical padding for the button container */
  }

  .apple-style-button, .learn-more-button, .visit-site-button {
      width: 90%;  /* Increases the width of buttons for better access */
      margin: 10px auto;  /* Centers buttons and adds spacing */
  }
}


.apple-style-button {
  padding: 10px 20px;
  border-radius: 20px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.apple-style-button.blue {
  background-color: #0070c9;
  border: 1px solid #0070c9;
  color: white;
}

.apple-style-button.blue:hover {
  background-color: #005fa3;
}

.apple-style-button.white {
  background-color: white;
  border: 1px solid #d1d1d6;
  color: #1d1d1f;
}

.apple-style-button.white:hover {
  border-color: #bcbcbc;
}
