/* ProjectDetail.css */

.project-detail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 100%; /* Limit the width to the viewport width */
}

.detail-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px 0;
    width: 80%; /* Adjust the width of detail items */
    max-width: 1200px; /* Optional: to prevent the detail item from becoming too wide on large screens */
    box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Optional: Adds a subtle shadow for depth */
    background-color: #fff; /* Optional: if you want a white background for the items */
}

.image-container img {
    max-width: 100%;
    height: 500px;
    display: block;
    object-fit: cover; /* Ensure the image covers the area nicely */
}

/* For odd items, image on the right, text on the left */
.detail-item:nth-child(odd) {
    flex-direction: row-reverse;
}

/* For even items, image on the left, text on the right */
.detail-item:nth-child(even) {
    flex-direction: row;
}

/* Adjust the padding for the text-container */
.text-container {
    flex: 1;
    padding: 20px;
    box-sizing: border-box; /* Padding included in the element's dimensions */
}

.image-container {
    flex: 1;
    padding: 20px; /* Adds padding around the image */
    box-sizing: border-box; /* Padding included in the element's dimensions */
}

@media (max-width: 768px) {
    .detail-item {
        flex-direction: column;
    }
    
    .text-container,
    .image-container {
        width: 100%; /* Full width on smaller screens */
        padding: 20px;
    }

    /* Here, we reset the order for mobile views to always have the image on top */
    .detail-item .image-container,
    .detail-item .text-container {
        order: 1;
    }

    .detail-item:nth-child(odd) .text-container,
    .detail-item:nth-child(even) .text-container {
        order: 2;
    }
}
