/* Contact.css */
.contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 400px;
    margin: auto;
}

.contact-form label {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 500;
}

.contact-form input, .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    box-sizing: border-box;
}

.contact-form textarea {
    height: 120px;
    resize: none; /* Disable textarea resize */
}

.contact-form button {
    background-color: #007aff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
}

.contact-form button:hover {
    background-color: #005ecb;
}

@media (max-width: 600px) {
    .contact-form {
        width: 80%; /* Smaller width on smaller screens */
        padding: 20px;
    }
}

/* Social links container */
.social-links {
    display: flex;
    align-items: center; /* Centers the items vertically */
    justify-content: center; /* Centers the items horizontally */
    gap: 20px; /* Adds space between the items */
    margin-top: 20px; /* Space above the social links */
}

/* Social links images */
.social-links img {
    width: 30px; /* Adjusts the size of the icons */
    height: auto; /* Maintains the aspect ratio of the icons */
    cursor: pointer; /* Indicates clickable items */
    transition: transform 0.3s ease; /* Smooth transformation on hover */
}

/* Hover effect for social links */
.social-links img:hover {
    transform: scale(1.1); /* Slightly enlarges the icons */
}
