body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

nav ul {
    list-style-type: none;
    padding: 0;
}

nav ul li {
    display: inline;
    margin-right: 20px;
}

.hero-section {
    text-align: center;
    padding: 50px 20px;
}

.project {
    margin: 20px;
    text-align: center;
}

footer {
    text-align: center;
    padding: 20px;
}

.cta-button {
    display: block;
    background-color: #007aff;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    text-align: center;
    width: fit-content;
    margin: auto;
}

.cta-button:hover {
    background-color: #005fa3;
}

.profile-picture {
    width: 150px; /* Keep the width moderate */
    height: 150px; /* Set a fixed height to maintain aspect ratio */
    border-radius: 50%; /* Keeps the image circular */
    object-fit: cover; /* Ensures the image covers the space well without distortion */
    border: 3px solid #fff; /* Adds a white border for better separation from the background */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Lightens the shadow for a softer effect */
    margin-right: 20px; /* Adds spacing between the image and the text */
}

.about-me-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 50px; /* Adjust spacing as needed */
    padding: 40px 0;
    max-width: 1024px; /* Restricts the maximum width */
    width: 100%; /* Uses full width on smaller screens */
    background: #fff; /* Optional: Set a background color */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Adds subtle shadow */
    border-radius: 10px; /* Optional: Rounds the corners */
    margin: auto; /* Centers the container */
}

.about-me-image {
    width: 150px; /* Adjust the size of the image */
    height: 150px; /* Maintain aspect ratio */
    border-radius: 50%; /* Circular image */
    margin-right: 20px; /* Space between image and text */
}

.about-me-text {
    flex: 1; /* Allows the text container to fill the remaining space */
    max-width: 800px; /* Limits the width of the text container for readability */

}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .about-me-container {
        flex-direction: column;
        text-align: center; /* Centers text for smaller screens */
    }

    .about-me-image {
        margin-bottom: 20px; /* Adds space between image and text on small screens */
        margin-right: 0; /* Removes right margin */
    }
}
.skills-section {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  padding: 20px;
  background-color: #f5f5f7;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.skills-container {
  display: flex;
  flex-direction: column; /* Align items in a column */
  width: 100%;
  gap: 20px; /* Adds space between each section */
  margin-top: 20px;
}

.about-me-container, 
.development-skills, 
.transferable-skills {
  margin: 0 auto; /* Centers the sections horizontally */
  padding: 20px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  width: 100%; /* Make it take up the full width of the container */
  max-width: 800px; /* Optionally, set a max width for readability */
  display: flex; /* Makes it a flex container */
  flex-direction: column; /* Content flows vertically */
  justify-content: space-between; /* Ensures even spacing inside */
  flex: 1; /* Ensures equal height for all sections */
}

.about-me-text, 
.development-skills ul, 
.transferable-skills ul {
  flex-grow: 1; /* Ensures content within doesn't affect height */
}

.development-skills h2, 
.transferable-skills h2,
.about-me-text h2 {
  color: #333;
  font-size: 22px;
  text-align: center; /* Center align headings */
}

.development-skills ul, 
.transferable-skills ul {
  list-style-type: none;
  padding: 0;
}

.development-skills li, 
.transferable-skills li {
  padding: 8px 0;
  border-bottom: 1px solid #eee;
  font-size: 16px;
}

/* Responsive adjustments (optional) */
@media (max-width: 768px) {
  .skills-container {
      gap: 10px; /* Adjust spacing for smaller screens */
  }
}
