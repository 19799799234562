.navbar {
    background: #fff; /* White background */
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
}

.nav-links {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center; /* Center links horizontally */
}

.nav-links li {
    padding: 20px;
}

.nav-links a {
    text-decoration: none;
    color: black; /* Black text */
    font-weight: bold;
    font-size: 16px;
}

.nav-links a:hover {
    color: #555; /* Dark grey for hover effect */
}


  